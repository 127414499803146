<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-24 14:29:18
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-05 09:20:52
-->
<template>
  <div class="Search">
    <div class="search-bar">
      <div class="search-bar-content">
        <SearhInput class="search-input" @data="findArticle"></SearhInput>
      </div>
    </div>

    <div class="search-content">
      <!-- 查询结果累计 -->
      <div class="search-resutl">
        <div class="search-resutl-title">{{ $t("search.SerachResult.SerachResultTitle") }}</div>
        <div class="remark">
          “
          <span class="search-key">{{ searchdata }}</span>
          ”{{ $t("search.SerachResult.SerachResultDesctwo") }}
          <span class="search-result-num">{{ searchDatas.total || 0 }}</span>
          {{ $t("search.SerachResult.SerachResultNum") }}
        </div>
      </div>
      <!-- 查询列表 -->
      <div class="search-result-list" v-if="hadResult">
        <div class="search-result-item" v-for="(i, index) in searchDatas.records" :key="index" @click="handleToArt(i, i.usageCategoryId, i.usageGroupId)">
          <div class="art-title">{{ i.articleName }}</div>
          <div class="art-remark">{{ i.articleDescribe }}</div>
        </div>
      </div>
      <div class="search-no-data" v-else>
        <Empty></Empty>
      </div>
    </div>
  </div>
</template>
<script>
import SearhInput from "./module/SearhInput";
import Empty from "./module/Empty";
export default {
  name: "search",
  components: { SearhInput, Empty },
  data() {
    return {
      hadResult: true,
      searchValue: "",
      pageIndex: 1,
      pageSize: 1000000,
      searchdata: "",
      searchDatas: {
        current: "",
        pages: "",
        records: [],
        total: "",
      },
    };
  },
  created() {},
  mounted() {
    this.findArticle();
  },
  computed: {
    language() {
      return this.$store.state.defaultlanguage;
    },
    usageManageId() {
      return this.$store.state.wikiAppId;
    },
    appId() {
      return this.$store.state.defaultlanDetail.appId;
    },
  },
  watch: {
    language() {
      this.findArticle();
    },
  },
  methods: {
    handleToArt(item, id, usageGroupId) {
      this.$router.push({
        name: "Detail",
        params: this.$route.params,
        query: { articleId: item.articleId, usageCategoryId: id, usageGroupId },
      });
    },

    findArticle(data) {
      this.searchdata = data || this.$route.query.searchValue;
      var params = {
        articleInfo: data || this.$route.query.searchValue,
        appId: this.appId,
        usageManageId: this.usageManageId,
        language: this.language,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      if (data == "") {
        params.articleInfo = "";
      }
      this.$api.findArticle(params).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.searchDatas = res.data;
            this.hadResult = true;
          } else {
            this.hadResult = false;
            this.data = { total: 0 };
          }
        } else {
          this.$message({
            type: "error",
            message: res.msg,
            duration: 1000,
            showClose: true,
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.search-bar,
.search-bar-content {
  width: 100%;
  height: 2rem;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.search-result-list {
  min-height: 65.8vh;
}
.search-bar-content {
  position: fixed;
  width: 100%;
  padding: 0.1538rem 0.5128rem;
  box-sizing: border-box;
}

.search-content {
  padding: 0 0.5128rem;

  .search-resutl {
    font-size: 0.3846rem;
    color: rgba(0, 0, 0, 0.6);
    line-height: 0.5385rem;
    padding: 0.6154rem 0;
    margin-top: 0.2564rem;
    box-shadow: 0 0.0256rem 0 0 rgba(0, 0, 0, 0.2);
    .search-resutl-title {
      font-size: 0.4872rem;
      color: rgba(0, 0, 0, 0.8);
      letter-spacing: -0.25px;
      font-weight: 500;
      margin-bottom: 0.2051rem;
    }
    .search-result-num {
      color: var(--theme-color);
    }
  }
  .search-result-item {
    padding: 0.6154rem 0;
    box-shadow: 0 0.0256rem 0 0 rgba(0, 0, 0, 0.2);
    .art-title {
      font-size: 0.4359rem;
      color: #000000;
      font-weight: 600;
      margin-bottom: 0.2051rem;
    }
    .art-remark {
      font-size: 0.3846rem;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 400;
      line-height: 0.6154rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
  .core {
    display: flex;
    align-items: center;
    margin-top: 0.359rem;
    margin-bottom: 0.359rem;
    .breadcrumb {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.4);
      letter-spacing: -0.2px;
      font-weight: 400;
    }
    .last {
      color: rgba(0, 0, 0, 0.8);
    }
    span {
      width: 0.4103rem;
      height: 0.4103rem;
      display: inline-block;
      margin: 0 0.1026rem;
    }
  }
}
</style>
