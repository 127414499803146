<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-24 15:51:55
 * @LastEditors: zhuxin xu
 * @LastEditTime: 2022-12-12 17:20:50
-->
<template>
  <div class="empty">
    <img class="no-data" src="@/assets/search-empty.png" alt srcset />
    <div class="title">{{$t('search.SerachResult.SerachResultTipTitle')}}</div>
    <div class="remark">{{$t('search.SerachResult.SerachResultTipRemark')}}</div>
  </div>
</template>
<script>
export default {
  name: "vue",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.empty {
  height: 65.8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .no-data {
    width: 2.0513rem;
    height: 2.0513rem;
    margin-bottom: 0.6154rem;
  }
  .title {
    font-size: 17px;
    color: #000000;
    text-align: center;
    font-weight: 500;
    margin-bottom: 0.2564rem;
  }
  .remark {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }
}
</style>
