<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-24 14:32:28
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-05 09:17:56
-->
<template>
  <div class="SearhInput">
    <svg-icon class="search-icon" @click="handleToSearch" iconClass="search-with-basis"></svg-icon>
    <input class="search-input" v-model="searchValue" maxlength="20" @keyup.enter="handleToSearch" :placeholder="$t('Header.SearhInput.placeholder')" />
    <span class="cancle-btn" @click="reture">取消</span>
  </div>
</template>
<script>
export default {
  props: ["data"],
  name: "SearhInput",
  data() {
    return {
      searchValue: this.$route.query.searchValue,
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleToSearch() {
      let timestamp = Math.random().toString();
      this.$router.replace({
        name: "Search",
        params: this.$route.params,
        query: { searchValue: this.searchValue, timestamp },
      });
      this.$emit("data", this.searchValue);
    },
    reture() {
      history.back();
    },
  },
};
</script>
<style scoped lang="scss">
.SearhInput {
  width: 100%;
  display: flex;
  height: 1.2308rem;
  position: relative;
  align-items: center;

  .search-icon {
    position: absolute;
    left: 16px;
    top: 12px;
    width: 0.6154rem;
    height: 0.6154rem;
  }
  .search-input {
    width: 82%;
    font-size: 0.4359rem;
    height: 100%;
    text-indent: 1.1538rem;
    box-sizing: border-box;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.6154rem;
    outline: none;
  }
  .cancle-btn {
    flex: 1;
    margin-left: 20px;
    width: 0.8718rem;
    display: block;
    font-size: 0.4359rem;
    color: var(--theme-color);
  }
}
</style>
